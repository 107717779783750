<template>
  <ul class="menu-nav">
    <li aria-haspopup="true" data-menu-toggle="click" class="menu-item menu-item-submenu menu-item-open-dropdown" v-bind:class="{ 'menu-item-active': hasActiveChildren('/dashboard') }">
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> SINYAL </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed" style="z-index:10000 !important">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item" style="width:250px;">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> BINANCE BSC</span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal') != undefined" to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1INCH BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_matcha_bsc') != undefined"
                  to="/dashboard_matcha_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Matcha BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_para_bsc') != undefined"
                  to="/dashboard_para_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Paraswap BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_dodo_bsc') != undefined"
                  to="/dashboard_dodo_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Dodo BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_1inch_bnb_bsc') != undefined"
                  to="/dashboard_1inch_bnb_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1Inch BNB BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_matcha_bnb_bsc') != undefined"
                  to="/dashboard_matcha_bnb_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Matcha BNB BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_paraswap_bnb_bsc') != undefined"
                  to="/dashboard_paraswap_bnb_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Paraswap BNB BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_1inch_btc_bsc') != undefined"
                  to="/dashboard_1inch_btc_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1Inch BTC BSC </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
            <li
              class="menu-item"
              style="width:250px;"
              v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_hotbit_matcha_bsc' || i == 'sinyal_hotbit_1inch_bsc') != undefined"
            >
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> HOTBIT BSC</span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_hotbit_1inch_bsc') != undefined"
                  to="/dashboard_hotbit_1inch_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1Inch BSC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_hotbit_matcha_bsc') != undefined"
                  to="/dashboard_hotbit_matcha_bsc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Matcha BSC </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
            <li
              class="menu-item"
              style="width:250px;"
              v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_erc' || i == 'sinyal_matcha_erc' || i == 'sinyal_1inch_eth_erc') != undefined"
            >
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> BINANCE ERC </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_erc') != undefined"
                  to="/dashboard_erc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1INCH ERC20 </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_1inch_eth_erc') != undefined"
                  to="/dashboard_1inch_eth_erc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1INCH ETH ERC20 </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_matcha_erc') != undefined"
                  to="/dashboard_matcha_erc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Matcha ERC20 </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
            <li class="menu-item" style="width:250px;" v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_huobi1inch_erc' || i == 'sinyal_huobidodo_erc') != undefined">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> HUOBI </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_huobi1inch_erc') != undefined"
                  to="/dashboard_huobi1inch_erc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">1INCH ERC </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.level_akses == 1 || currentUser.fitur.find(i => i == 'sinyal_huobidodo_erc') != undefined"
                  to="/dashboard_huobidodo_erc"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Dodo ERC </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <router-link to="/setting" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Setting </span>
        </a>
      </li>
    </router-link>
    <router-link to="/data" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Data </span>
        </a>
      </li>
    </router-link>
    <router-link v-if="currentUser.level_akses == 1" to="/user_manager" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> User Manager </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
