var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('/dashboard') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-fixed",staticStyle:{"z-index":"10000 !important"}},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px"}},[_c('ul',{staticClass:"menu-content"},[_c('li',{staticClass:"menu-item",staticStyle:{"width":"250px"}},[_vm._m(1),_c('ul',{staticClass:"menu-inner"},[(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1INCH BSC ")])])])]}}],null,false,3748116303)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_matcha_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_matcha_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Matcha BSC ")])])])]}}],null,false,625363776)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_para_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_para_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Paraswap BSC ")])])])]}}],null,false,3267175493)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_dodo_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_dodo_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Dodo BSC ")])])])]}}],null,false,1971085170)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_1inch_bnb_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_1inch_bnb_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1Inch BNB BSC ")])])])]}}],null,false,3216495873)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_matcha_bnb_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_matcha_bnb_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Matcha BNB BSC ")])])])]}}],null,false,4184602862)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_paraswap_bnb_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_paraswap_bnb_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Paraswap BNB BSC ")])])])]}}],null,false,3676392043)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_1inch_btc_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_1inch_btc_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1Inch BTC BSC ")])])])]}}],null,false,2217928474)}):_vm._e()],1)]),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_hotbit_matcha_bsc' || i == 'sinyal_hotbit_1inch_bsc'; }) != undefined)?_c('li',{staticClass:"menu-item",staticStyle:{"width":"250px"}},[_vm._m(2),_c('ul',{staticClass:"menu-inner"},[(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_hotbit_1inch_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_hotbit_1inch_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1Inch BSC ")])])])]}}],null,false,191248239)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_hotbit_matcha_bsc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_hotbit_matcha_bsc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Matcha BSC ")])])])]}}],null,false,625363776)}):_vm._e()],1)]):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_erc' || i == 'sinyal_matcha_erc' || i == 'sinyal_1inch_eth_erc'; }) != undefined)?_c('li',{staticClass:"menu-item",staticStyle:{"width":"250px"}},[_vm._m(3),_c('ul',{staticClass:"menu-inner"},[(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_erc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_erc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1INCH ERC20 ")])])])]}}],null,false,2547772459)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_1inch_eth_erc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_1inch_eth_erc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1INCH ETH ERC20 ")])])])]}}],null,false,3561773138)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_matcha_erc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_matcha_erc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Matcha ERC20 ")])])])]}}],null,false,2028700068)}):_vm._e()],1)]):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_huobi1inch_erc' || i == 'sinyal_huobidodo_erc'; }) != undefined)?_c('li',{staticClass:"menu-item",staticStyle:{"width":"250px"}},[_vm._m(4),_c('ul',{staticClass:"menu-inner"},[(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_huobi1inch_erc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_huobi1inch_erc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("1INCH ERC ")])])])]}}],null,false,490709481)}):_vm._e(),(_vm.currentUser.level_akses == 1 || _vm.currentUser.fitur.find(function (i) { return i == 'sinyal_huobidodo_erc'; }) != undefined)?_c('router-link',{attrs:{"to":"/dashboard_huobidodo_erc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Dodo ERC ")])])])]}}],null,false,3346467284)}):_vm._e()],1)]):_vm._e()])])])]),_c('router-link',{attrs:{"to":"/setting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Setting ")])])])]}}])}),_c('router-link',{attrs:{"to":"/data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Data ")])])])]}}])}),(_vm.currentUser.level_akses == 1)?_c('router-link',{attrs:{"to":"/user_manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" User Manager ")])])])]}}],null,false,1402049570)}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" SINYAL ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"menu-heading menu-toggle"},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" BINANCE BSC")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"menu-heading menu-toggle"},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" HOTBIT BSC")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"menu-heading menu-toggle"},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" BINANCE ERC ")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"menu-heading menu-toggle"},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" HUOBI ")]),_c('i',{staticClass:"menu-arrow"})])}]

export { render, staticRenderFns }